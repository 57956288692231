import React from "react"
import { graphql } from "gatsby"

// import SEO from "../components/seo"

import Layout from "../components/layout"
import ProjectList from "../components/lists/projectList"

const ProductTemplate = ({ data }) => {
  // const { data } = props

  // console.log(data)
  // const productNodes =
  //   data &&
  //   data.products &&
  //   mapEdgesToNodes(data.products).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <div className="container mt-12 mb-20">
        <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl ">
          {data.category.title}
        </h1>
      </div>
      <div className="pb-32">
        <ProjectList projects={data.projects} />
      </div>
    </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query CategoryTemplateQuery($title: String!) {
    category: sanityCategory(title: { eq: $title }) {
      title
    }
    projects: allSanityProject(
      filter: { categories: { elemMatch: { title: { eq: $title } } } }
      sort: { order: DESC, fields: _updatedAt }
    ) {
      edges {
        node {
          title
          categories {
            title
          }
          mainImage {
            ...SanityImage
          }
          _rawBody(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`

export default ProductTemplate
